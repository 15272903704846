import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Cart} from '../../../domain/models/order/cart';
import {OfflineCart} from '../../../domain/models/order/offline-cart';
import {StoreProfile} from '../../../domain/models/store/store-profile';
import {Store} from '../../../domain/models/store/store';
import {Paginated} from '../../../transport/models/paginated';
import {Product} from '../../../domain/models/product/product';
import {AdjustmentType} from '../../../domain/models/product/adjustmentType';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreService} from '../../../domain/store.service';
import {ScannerService} from '../../../domain/scanner.service';
import {CartService} from '../../../domain/cart.service';
import {OfflineCartService} from '../../../domain/offline-cart.service';
import {ProductService} from '../../../domain/product.service';
import {PaymentService} from '../../../domain/payment.service';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {CartUtils} from '../../../utils/cart.utils';
import {CampaignOrderLine, OrderLineType, ProductOrderLine} from '../../../domain/models/order/order-line';
import {PaymentIdentifier, PaymentMethod} from '../../../domain/models/payment/payment-method';
import {AdyenSession, InitializePaymentResponse} from '../../../transport/models/payment/initialize-payment.response';
import {MessageDialog} from '../../../dialogs/message/message.dialog';
import {ForegroundPaths} from '../../../app-routing.module';
import AdyenCheckout from '@adyen/adyen-web';
import {ConfirmDialog} from '../../../dialogs/confirm/confirm.dialog';
import {AdjustmentTypeDialog} from '../../../dialogs/adjustment-type/adjustment-type-dialog';
import {AgeLimitService} from '../../../domain/age-limit.service';
import {ShipmentsheetDialog} from '../../../dialogs/actionsheet/shipmentsheet/shipmentsheet.dialog';
import {ShippingAddress} from '../../../domain/models/order/shipping-address';
import {SafeResourceUrl} from '@angular/platform-browser';
import {delay} from 'src/app/utils/promise.utils';
import {isPaid, isPending} from '../../../utils/order.utils';
import {LoadJsFilesService} from '../../../domain/load-js-files.service';
import {TransactionPayment} from '../../../domain/models/order/transaction';
import Bugsnag from '@bugsnag/js';
import {OrderService} from '../../../domain/order.service';
import {httpUtils} from '../../../utils/http.utils';
import {CustomerService} from '../../../domain/customer.service';

declare const VippsCheckout: any;

@Component({
  selector: 'app-cart-payment',
  templateUrl: './cart-payment.component.html',
  styleUrls: ['./cart-payment.component.sass']
})
export class CartPaymentComponent implements OnInit, OnDestroy {
  cart?: Cart;
  offlineCart?: OfflineCart | null;
  profile?: StoreProfile;
  store?: Store;
  ageLimitProducts = false;
  showExternalPaymentFlow = false;
  isCartBusy = false;
  isPaymentBusy = false;
  error: any;
  requiresShipmentAddress = false;
  cancelPolling = false;
  orderPaymentAttempts = 0;
  maxOrderPaymentAttempts = 600; // This is ~10 minutes. Keep this artificially high for now until we can replace polling with a websocket
  extPaymentIframeSrc?: SafeResourceUrl;
  currencyCode?: string;
  cultureName?: string;
  applePay?: PaymentMethod;
  googlePay?: PaymentMethod;
  vrPay?: PaymentMethod[];
  paymentMethods?: Array<PaymentMethod>;
  shoppingBag?: Paginated<Product> | void;
  currentAdjustmentType: AdjustmentType | undefined;
  discountCodeEnabled = false;
  cartCount?: string;
  applePayLoaded = true;
  googlePayLoaded = true;
  vrPayLoaded = false;
  vippsLibrary?: Promise<HTMLScriptElement>;
  readonly vippsCheckoutJsFile = 'https://checkout.vipps.no/vippsCheckoutSDK.js';

  private cartChangeSubscription: Subscription | undefined;
  private offlineCartChangeSubscription: Subscription | undefined;
  private vippsCheckout: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private storeService: StoreService,
              private scannerService: ScannerService,
              private cartService: CartService,
              private offlineCartService: OfflineCartService,
              private productService: ProductService,
              private ageLimitService: AgeLimitService,
              private paymentService: PaymentService,
              private dialogService: MatDialog,
              private translateService: TranslateService,
              private bottomSheet: MatBottomSheet,
              private loadJsFilesService: LoadJsFilesService,
              private renderer: Renderer2,
              private orderService: OrderService,
              private customerService: CustomerService,
  ) {
  }

  async ngOnInit() {
    if (!this.router.url.includes('/store/')) {
      return;
    }
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.customerService.initCustomerFromAuth(this.store.storeChain.id);
    const store = this.store;
    this.profile = this.store.storeProfile;

    this.setCart(await this.cartService.getOrCreateLocalCart(storeHandle), store);
    this.offlineCart = this.offlineCartService.getCart(storeHandle);
    this.cartChangeSubscription = this.cartService
      .onCartChanges(storeHandle)
      .subscribe(value => this.setCart(value, store));
    this.offlineCartChangeSubscription = this.offlineCartService
      .onCartChanges(storeHandle)
      .subscribe(value => this.offlineCart = value);

    if (this.cart?.orderId && await this.shouldInspectOrder(this.cart.orderId, storeHandle)) {
      // Cancel and route to inspection
      return;
    }

    this.vippsLibrary = this.loadJsFilesService.loadJsScript(this.renderer, this.vippsCheckoutJsFile);

    this.currencyCode = this.store?.currencyCode;
    this.cultureName = this.store?.cultureName;
    this.shoppingBag = await this.productService.getShoppingBagProducts(storeHandle);
    if (this.cart) {
      this.cartCount = await this.cartService.getCartCount(this.cart);
    }
    await this.checkAdjustmentType();
    await this.getDiscountSetting();

    const directPaymentMethod = await this.paymentService.getDirectPaymentOption(this.store.id);
    if (directPaymentMethod && this.isCartValid(this.cart)) {
      await this.initializePayment(directPaymentMethod);
    } else {
      try {
        this.paymentMethods = await this.paymentService.getPaymentMethods(this.store.id, this.store.storeChain.id);
      } catch (error) {
        if (error.status == 404 || error.status == 400) {
          this.paymentMethods = undefined;
        } else {
          throw error;
        }
      }

      this.applePay = this.paymentMethods?.find(paymentMethod =>
        paymentMethod.identifier == PaymentIdentifier.checkoutApplePay ||
        paymentMethod.identifier == PaymentIdentifier.weatApplePay ||
        paymentMethod.identifier == PaymentIdentifier.UnzerApplePay,
      );
      this.googlePay = this.paymentMethods?.find(paymentMethod =>
        paymentMethod.identifier == PaymentIdentifier.checkoutGooglePay ||
        paymentMethod.identifier == PaymentIdentifier.weatGooglePay,
      );
      this.vrPay = this.paymentMethods?.filter(paymentMethod =>
        paymentMethod.identifier == PaymentIdentifier.VrApplePay ||
        paymentMethod.identifier == PaymentIdentifier.VrGooglePay,
      );
    }
  }

  ngOnDestroy(): void {
    this.cartChangeSubscription?.unsubscribe();
    this.offlineCartChangeSubscription?.unsubscribe();
    this.cancelPolling = true;
  }

  private setCart(cart: Cart, store: Store) {
    this.requiresShipmentAddress = CartUtils.requiresShipmentAddress(store, cart);
    this.cart = cart;

    if (!this.ageLimitService.isCartChecked(cart.orderId!)) {
      cart.orderLines.forEach(ol => {
        if (this.ageLimitService.requiresCheck(ol.id))
          this.ageLimitProducts = true;
      });
    }
  }

  async initializePayment(method: PaymentMethod) {
    this.scannerService.scanner?.stopScan();
    this.cancelPolling = false;
    this.orderPaymentAttempts = 0;

    if (this.cart?.adjustmentTypeId == undefined && this.store?.adjustmentTypes != undefined && this.store?.adjustmentTypes.length > 0) {
      await this.checkAdjustmentType();
      return;
    } else if (method.identifier === PaymentIdentifier.Softpay) {
      // Softpay needs device ID before we can initialize payment
      await this.router.navigate(ForegroundPaths.cartPaymentSoftpay());
      return;
    } else {
      this.error = undefined;
      this.isPaymentBusy = true;
      const storeHandle = this.store!.handle;
      let response: InitializePaymentResponse;

      try {
        response = await this.paymentService.initialize(method.identifier, this.cart!);
      } catch (errorResponse) {
        try {
          await PaymentService.errorHandler(errorResponse, {
            orderIsPaid: async () => {
              // Order has already been paid; do a full page reload to recover state
              await this.router.navigate(ForegroundPaths.empty());
              await delay(3000);
              window.location.reload();
            },
            productNotFound: async () => {
              this.cartService.clearCart(storeHandle);
              await this.router.navigate(ForegroundPaths.paymentFailed());
            },
            dimensionOutOfStock: msg => {
              this.error = msg;
            },
          });
        } catch (error) {
          await delay(1000);
          await this.router.navigate(ForegroundPaths.paymentFailed()).then(() => {
            // Hack in order to restore related products in cart:
            window.location.reload();
          });
        }

        this.isPaymentBusy = false;
        return null;
      }

      if (response.identifier === PaymentIdentifier.Adyen) {
        const adyenSession = response.data as AdyenSession;

        const configuration = {
          environment: adyenSession.environment,
          clientKey: adyenSession.clientKey,
          session: {
            id: adyenSession.id,
            sessionData: adyenSession.data
          },
          paymentMethodsConfiguration: {
            applepay: {
              onValidateMerchant: async (resolve: any, reject: any, validationURL: any) => {
                await this.paymentService
                  .applePayValidateMerchant(this.cart!.orderId!, validationURL)
                  .then((response: any) => resolve(response))
                  .catch(() => reject());
              }
            },
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              billingAddressRequired: false
            }
          },
          onError: async (error: any, component: any) => {
            component.setStatus('ready');
            let text = await this.translateService.get('DIALOG.PAYMENT.text').toPromise();
            text = text + ' (' + error.name + ')';
            const confirm = await this.translateService.get('DIALOG.PAYMENT.ok').toPromise();
            const title = await this.translateService.get('DIALOG.PAYMENT.title').toPromise();
            const dialogRef = this.dialogService.open(MessageDialog, {
              width: '250px',
              data: {
                text,
                confirm,
                title
              }
            });
            await dialogRef.afterClosed().toPromise();
          },
          onPaymentCompleted: async (result: any, component: any) => {
            if (result.resultCode == 'Refused') {
              component.setStatus('ready');
              let text = await this.translateService.get('DIALOG.PAYMENT.text').toPromise();
              text = text + ' (' + result.resultCode + ')';
              const confirm = await this.translateService.get('DIALOG.PAYMENT.ok').toPromise();
              const title = await this.translateService.get('DIALOG.PAYMENT.title').toPromise();
              const dialogRef = this.dialogService.open(MessageDialog, {
                width: '250px',
                data: {
                  text,
                  confirm,
                  title
                }
              });
              await dialogRef.afterClosed().toPromise();
            } else {
              await this.router.navigate(ForegroundPaths.empty());
              await this.router.navigate([`/store/${storeHandle}/receipt/${response.orderId}`]);
            }
          },
        };
        this.showExternalPaymentFlow = true;
        const checkout = await AdyenCheckout(configuration);
        checkout.create('dropin').mount('#dropin-container');
        return;
      } else if (response.identifier === PaymentIdentifier.VippsU15) {
        this.showExternalPaymentFlow = true;
        await this.refreshPaymentStatus();
        return;
      } else if (
        response.identifier === PaymentIdentifier.Vipps ||
        response.identifier === PaymentIdentifier.Swish ||
        response.identifier === PaymentIdentifier.Mastercard ||
        response.identifier === PaymentIdentifier.Checkout ||
        response.identifier === PaymentIdentifier.Buypass ||
        response.identifier === PaymentIdentifier.UnzerHosted ||
        response.identifier === PaymentIdentifier.Verifone ||
        response.identifier === PaymentIdentifier.SwedbankPay
      ) {
        window.location.href = response.data.url;
        await this.refreshPaymentStatus();
        return;
      } else if (response.identifier === PaymentIdentifier.Cash) {
        await this.router.navigate(ForegroundPaths.cartPaymentCash(response.orderId));
      } else if (response.identifier === PaymentIdentifier.Demo) {
        await this.router.navigate(ForegroundPaths.empty());
        await this.router.navigate([`/store/${storeHandle}/receipt/${response.orderId}`]);
      } else if (response.identifier === PaymentIdentifier.VippsCheckout) {
        this.showExternalPaymentFlow = true;

        // Need to use setTimeout to allow the external payment flow to be rendered
        // before using it in the Vipps SDK
        setTimeout(async () => {
          try {
            await this.vippsLibrary;
          } catch (error) {
            throw new Error('Vipps checkout library not loaded');
          }

          this.vippsCheckout = VippsCheckout({
            checkoutFrontendUrl: response.data.url,
            iFrameContainerId: 'dropin-container',
            language: 'no',
            token: response.data.token,
            on: {
              session_status_changed: async (data: any) => {
                if (data === 'PaymentSuccessful') {
                  await this.router.navigate(ForegroundPaths.empty());
                  await this.router.navigate([`/store/${storeHandle}/receipt/${response.orderId}`]);
                } else if (data === 'PaymentFailed' || data === 'SessionTerminated') {
                  await this.router.navigate(ForegroundPaths.paymentFailed());
                }
              },
            },
          });
        });
      }

      this.isPaymentBusy = false;
      return;
    }
  }

  private async refreshPaymentStatus() {
    if (this.cart == null || this.cart.orderId == null)
      throw Error('Cannot refresh payment status, no cart.');
    if (this.cancelPolling) {
      return;
    } else if (this.orderPaymentAttempts >= this.maxOrderPaymentAttempts) {
      this.cancelPolling = true;
      Bugsnag.notify({
        name: 'Pre payment failed after max attempts',
        message: `Payment failed after max attempts: ${this.maxOrderPaymentAttempts}`,
      });
      this.router.navigate(ForegroundPaths.paymentFailed());
      return;
    }

    this.orderPaymentAttempts++;
    let transaction: TransactionPayment;

    try {
      transaction = await this.paymentService.refreshPaymentStatus(this.cart.orderId);
    } catch (error) {
      if (httpUtils(error).isAbortError()) {
        await delay(2000);
        await this.refreshPaymentStatus();
        return;
      } else if (error.error?.title && error.error?.detail) {
        Bugsnag.notify(
          {name: 'Refresh pre payment status failed', message: `${error.error.detail}: ${error.error.title}`},
          event => event.addMetadata('Response', error),
        );
      }
      await this.handlePaymentFailed();
      return;
    }

    if (isPaid(transaction)) {
      await this.router.navigate(ForegroundPaths.empty());
      await this.router.navigate([`/store/${this.store?.handle}/receipt/${this.cart.orderId}`]);
    } else if (isPending(transaction) && !this.cancelPolling) {
      await delay(1000);
      await this.refreshPaymentStatus();
    }

    if (transaction.status == 'failed') {
      await this.handlePaymentFailed();
    }
  }

  private async confirmRemoval(): Promise<boolean> {
    const text = await this.translateService.get('DIALOG.REMOVEPRODUCT.content').toPromise();
    const confirm = await this.translateService.get('DIALOG.REMOVEPRODUCT.submit').toPromise();
    const dialogRef = this.dialogService.open(ConfirmDialog, {
      width: '250px',
      data: {
        text,
        confirm,
      }
    });
    const result = await dialogRef.afterClosed().toPromise();
    return result === true;
  }

  isOrderLineValid(orderLine: ProductOrderLine | CampaignOrderLine): boolean {
    return !(orderLine.type === OrderLineType.Product && orderLine?.product?.isWeight && orderLine?.quantity === 0);

  }

  isCartValid(cart?: Cart): boolean {
    if (!cart || !this.store) {
      return false;
    }
    const hasInvalidOrderLines = cart.orderLines.filter(line => !(this.isOrderLineValid(line))).length > 0;
    const requiresShipmentAddress = CartUtils.requiresShipmentAddress(this.store, cart);
    const hasShippingAddress = cart.shippingAddress != null;
    const hasValidShipmentAddress = !requiresShipmentAddress || hasShippingAddress;
    return cart.sum > 0 && !hasInvalidOrderLines && hasValidShipmentAddress;
  }

  async checkAdjustmentType() {
    const cart = await this.cartService.getOrCreateLocalCart(this.store?.handle!);
    if (cart.adjustmentTypeId !== undefined) {
      const adjustmentType = this.store?.adjustmentTypes.find(at => at.id === cart.adjustmentTypeId);
      if (adjustmentType !== undefined) {
        this.currentAdjustmentType = adjustmentType;
      }
    } else {
      if (this.store?.adjustmentTypes != undefined && this.store?.adjustmentTypes.length > 0) {
        await this.requestAdjustment();
      }
    }
  }

  async requestAdjustment() {
    const result = await this.dialogService.open(AdjustmentTypeDialog, {
      width: '250px',
      data: this.store?.adjustmentTypes,
      disableClose: true,
    }).afterClosed().toPromise();
    const selected = result as AdjustmentType;
    await this.cartService.setAdjustmentType(this.store?.handle!, selected!);
    this.currentAdjustmentType = selected!;
  }

  private async getDiscountSetting() {
    if (this.store) {
      try {
        this.discountCodeEnabled = await this.storeService.isDiscountCodeEnabled(this.store.handle);
      } catch (error) {
        if (error.status == 404) {
          this.discountCodeEnabled = false;
        } else {
          throw error;
        }
      }
    }
  }

  async ageCheck() {
    await this.router.navigate(ForegroundPaths.ageLimit());
  }

  resetForm() {
    this.showExternalPaymentFlow = false;
    this.isPaymentBusy = false;
  }

  async openCart() {
    await this.router.navigate(ForegroundPaths.cart());
  }

  async openShipmentSheet() {
    const result = await this.bottomSheet.open(ShipmentsheetDialog, {
        data: this.cart?.shippingAddress,
        panelClass: 'full-screen-bottom-sheet',
      },
    ).afterDismissed().toPromise();
    if (result as ShippingAddress && this.cart) {
      this.cart.shippingAddress = result;
      if (this.store?.handle && result != null) {
        await this.cartService.setShipmentAddress(this.store?.handle, result);
      }
    }
  }

  getClientHeight() {
    return window.innerHeight;
  }

  async cancelPayment() {
    this.cancelPolling = true;
    await this.router.navigate(ForegroundPaths.cart());
  }

  async handlePaymentFailed() {
    this.cancelPolling = true;
    await this.router.navigate(ForegroundPaths.paymentFailed()).then(() => {
      // Hack in order to restore related products in cart:
      window.location.reload();
    });
  }

  async shouldInspectOrder(orderId: string, storeHandle: string): Promise<boolean> {
    if (this.orderService.isOrderInspected(orderId)) {
      return false;
    }

    const shouldInspect = await this.orderService.getInspectionStatus(orderId)
      .then(status => status.inspection)
      .catch((error) => {
        if (httpUtils(error).isNotStatus(404)) {
          Bugsnag.notify(error);
        }
        return false;
      });

    if (shouldInspect) {
      await this.router.navigate([`/store/${storeHandle}`]);
      await this.router.navigate(ForegroundPaths.inspection(orderId));
      return true;
    }

    return false;
  }
}
