import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfig} from '../app.config';
import {StoreSetting} from '../domain/models/store/store-setting';
import {Cache} from '../utils/cache.utils';

@Injectable({
  providedIn: 'root',
})
export class StoreSettingsProvider {
  private _cache = new Cache<StoreSetting[]>();
  private _promise: Map<string, Promise<StoreSetting[]>> = new Map();

  static ProductGroup  = 'product';
  static ScanningGroup  = 'scanning';
  static OrderGroup  = 'order';
  static CartGroup  = 'cart';
  static PaymentsGroup  = 'payments';
  static GiftReceiptGroup  = 'gift-receipt';

  constructor(private http: HttpClient) {
  }

  async getAllSettings(storeHandle: string): Promise<StoreSetting[]> {
    const cached = this._cache.get(storeHandle);
    if (cached) {
      return cached;
    }

    let promise = this._promise.get(storeHandle);
    if (promise == null) {
      promise = this.fetchAllSettings(storeHandle);
      this._promise.set(storeHandle, promise);
    }
    return promise;
  }

  async getSetting(storeHandle: string, groupName: string, key: string): Promise<StoreSetting | undefined> {
    const settings = await this.getAllSettings(storeHandle);
    return settings.find(value => value.groupName == groupName && value.key == key);
  }

  private async fetchAllSettings(storeHandle: string): Promise<StoreSetting[]> {
    const settings = await this.http.get<StoreSetting[]>(`${AppConfig.getApiUrl()}/settings/${storeHandle}`, {
      headers: {'auth-key': AppConfig.authKey},
    }).toPromise();
    this._cache.set(storeHandle, settings);
    return settings;
  }
}

